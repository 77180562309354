export const Colors = {
    Orange: '#ec663b',
    Yellow: '#ec9e28',
    LightGray: 'rgba(241, 91, 41, 0.5)',
    AshGray: '#aeaeae',
    Dark: '#191c27',
    White: '#ffffff',
    Black: '#000000',
    BorderColor: '#dddddd',
    Error: '#ea4335'
}