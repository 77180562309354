import {Icons} from "../Icons";
const userRoles = require("./userRoles.json"),
  footerLinks = require("./footerLinks.json"),
  adminNav = require("./adminNav.json"),
  orderOptions = require("./orderOptions.json"),
  statusOptions = require("./statusOptions.json"),
  robotsOptions = require("./robotsOptions.json"),
  authorOptions = require("./faqCategories.json"),
  faqCategories = require("./faqCategories.json"),
  accessMatrix = require("./accessMatrix.json"),
  creditScoreData = require("./creditScoreData.json"),
  defaultersStatus = [
    {value: "all", label: "All"},
    {value: "processing", label: "Processing", icon: Icons["status-yellow"]},
    {
      value: "more_details_required",
      label: "More Documents Req",
      icon: Icons["status-orange"],
    },
    {value: "verified", label: "Verified", icon: Icons["status-blue"]},
    {value: "rejected", label: "Rejected", icon: Icons["status-red"]},
    {
      value: "partial_settled",
      label: "Partial Settled",
      icon: Icons["status-gray"],
    },
    {value: "full_settled", label: "Full Settled", icon: Icons["status-green"]},
    {value: "incomplete", label: "Incomplete", icon: Icons["status-brown"]},
    {value: "removed", label: "Removed", icon: Icons["status-red"]},
    {
      value: "wrongly_reported",
      label: "wrongly reported",
      icon: Icons["status-red"],
    },
  ],
  defaultersStatusObject = require("./defaultersStatusObject.json"),
  statusObject = require("./statusObject.json"),
  chartColors = require("./chartColors.json");
export {
  chartColors,
  accessMatrix,
  userRoles,
  footerLinks,
  adminNav,
  statusObject,
  orderOptions,
  statusOptions,
  robotsOptions,
  authorOptions,
  faqCategories,
  creditScoreData,
  defaultersStatus,
  defaultersStatusObject,
};
