import React, { useEffect, useState } from 'react'
import { getLocationData } from '../services/Router';
const Img = (props) => {
    let {src} = props
    let [imageSrc, setimageSrc] = useState('/blank.png')
    useEffect(() => {
        let activeRoute = getLocationData()
        if(activeRoute.href.search("admin") === -1) {
        // setTimeout(() => {
            setimageSrc(src)
        // }, 3000);
        } else {
            setimageSrc(src)
        }
    }, [src])
    return <img
        {...props}
        src={imageSrc}
    />
}
export default Img