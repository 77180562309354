import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {unregister} from './serviceWorker';
import { Provider } from 'react-redux'
import { createReduxStore } from './redux/reducers/index';
import 'react-datepicker/dist/react-datepicker.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
Sentry.init({
  dsn: "https://ca33208cd8974ceb887a04bb98592a03@o999254.ingest.sentry.io/5958200",
  autoSessionTracking:true,
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})
const app = (
    <Provider store={createReduxStore()}>
      <App />
    </Provider>
)
ReactDOM.render(
    app,
    document.getElementById('root')
)
unregister()