/*
*  @author       :  Prashant Kapoor
*  @license      :  MIT
*  @created      :  04/09/2021
*  @lastModified :  04/09/2021
*  @modifiedBy   :  Mukesh Majoka
*  @type         :  service
*/
const setTitle = (title, type='public') => {
    let homeTitle = 'CreditQ- MSME Membership, report defaulter, settlement, CIR'
    let siteTitle = 'CreditQ'
    let siteTagline = 'India\'s first Business Credit Management & Information Portal for Businessmen.'
    title = type === 'admin'?title+' | '+siteTitle+' Admin':type === 'home'?homeTitle:title+' | '+siteTitle
    document.title = title
},
setDescription = (description, type='public') => {
    description = type === 'home' ? 'CreditQ, India\'s 1st platform for MSME & businesses, to report business credit defaulter, increase the scope of settle payment, business credit report.':description
    document.querySelector('meta[name="description"]').setAttribute("content", description);
},
setKeywords = (keywords, type='public') => {
    document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
},
setCanonical = (url) => {
    url = url && url !== '' ?url:'https://creditq.in'+window.location.pathname
    document.querySelector('link[rel="canonical"]').setAttribute("href", url);
}
export {
    setTitle,
    setDescription,
    setKeywords,
    setCanonical
}