import { createStore } from 'redux';
export const ACTIONS = {
  USER: 'USER',
};
const initialState = {
  user: localStorage.getItem('state')
};
function foodReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.USER: {
      localStorage.setItem('state', JSON.stringify(action.payload.user))
      return {
        ...state,
        user: action.payload
      };
    }
    default:
      return state;
  }
}
const enableReduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__?.()
export function createReduxStore() {
  const store = createStore(foodReducer, enableReduxDevTools);
  return store;
}