import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./context/auth";
import { checkAccessRoute } from "./services/Misc";
const AdminRoute = ({ component: Component, accessRoles, ...rest }) => {
    const { authTokens } = useAuth();
    let allowed = false;
    let user = null;
    if (authTokens) {
      user = JSON.parse(authTokens);
      allowed = accessRoles.includes(user?.role?.role_type);
    }
    let backUrl = "/home";
    if (user) {
      allowed = checkAccessRoute(rest.path);
      backUrl = "/admin/dashboard";
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          allowed ? (
            <Component user={user} {...props} />
          ) : (
            <Redirect
              to={{ pathname: backUrl, state: { referer: props.location } }}
            />
          )
        }
      />
    );
  },
  UserRoute = ({
    component: Component,
    business,
    accessRoles,
    userDetails,
    ...rest
  }) => {
    const { authTokens } = useAuth();
    let allowed = false,
      user = null;
    if (authTokens) {
      user = JSON.parse(authTokens);
      allowed = accessRoles.includes(user?.role?.role_type);
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          allowed
            ? ((props.userDetails = userDetails),
              (props.user = user),
              (<Component {...props} />))
            : ((props.userDetails = null),
              (props.user = null),
              (
                <Redirect
                  to={{ pathname: "/", state: { referer: props.location } }}
                />
              ))
        }
      />
    );
  },
  PublicRoute = ({ component: Component, page, ...rest }) => {
    const { authTokens } = useAuth();
    let user = null;
    if (authTokens) {
      user = JSON.parse(authTokens);
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          page && page === "login" && user ? (
            <Redirect
              to={{ pathname: "/", state: { referer: props.location } }}
            />
          ) : Component ? (
            <Component user={user} {...props} />
          ) : (
            <Redirect to={rest.redirectTo} />
          )
        }
      />
    );
  };
export { AdminRoute, UserRoute, PublicRoute };
