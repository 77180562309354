const VERSION = ""; //'v2/'
const mainUrl = process.env.REACT_APP_MAIN_URL,
  base = {
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    googleApiKeyWebOnly: process.env.REACT_APP_GOOGLE_API_KEY_WEB_ONLY,
    url: process.env.REACT_APP_MAIN_URL,
    api: process.env.REACT_APP_API + VERSION,
    profileImageUrl: process.env.REACT_APP_PROFILE_IMAGE_URL,
    imageBaseUrl: process.env.REACT_APP_IMAGE_BASE_URL,
    docBaseUrl: process.env.REACT_APP_DOC_BASE_URL,
    jaipurCoordinates: {
      lat: process.env.REACT_APP_JAIPUR_COORDINATES_LAT,
      lng: process.env.REACT_APP_JAIPUR_COORDINATES_LNG,
    },
    androidApp: process.env.REACT_APP_ANDROID_APP,
    iosApp: process.env.REACT_APP_IOS_APP,
    androidAppImage: process.env.REACT_APP_IMAGE_BASE_URL + "google-play.png",
    iosAppImage: process.env.REACT_APP_IMAGE_BASE_URL + "app-store.png",
    fbAppId: process.env.REACT_APP_FB_APP_ID,
    tinyMceApiKey: process.env.REACT_APP_TINY_MCE_API_KEY,
    tinyMceEditorOptions: {
      height: 760,
      custom_elements: "style",
      menubar: false,
      relative_urls: false,
      remove_script_host: false,
      plugins: [
        "advlist lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code wordcount",
      ],
      toolbar:
        "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code",
    },
  },
  config = {
    sendMail: base.api + "utils/sendmail",
  },
  socialLinks = {
    fb: "https://www.facebook.com/creditq.in/",
    insta: "https://www.instagram.com/creditq.in/?igshid=1ofq1qtit4ebk",
    linkedin: "https://www.linkedin.com/company/creditq-in/",
    twitter: "https://twitter.com/Creditq6",
    youtube:
      "https://www.youtube.com/channel/UCFfeQmdNjEPvCGqglgtzfVQ?view_as=subscriber",
    pinterest: "https://in.pinterest.com/icreditq",
    whatsapp:
      "https://api.whatsapp.com/send/?phone=919509620034&text&app_absent=0",
  },
  brandName = {
    uppercase: "CREDITQ",
    lowercase: "creditq",
    capital: "CreditQ",
  },
  brandInfo = {
    logo: base.imageBaseUrl + "logo.png",
    phone: "18008901761",
    mail: "info@creditq.in",
    supportMail: "support@creditq.in",
    siteName: "creditq.in",
    addressJaipur:
      "3, 31, Chitrakoot Marg, Vaishali Nagar, Jaipur, Rajasthan 302021",
    addressDelhi:
      "Building No. - 55, 2nd Floor, Panchkuian Marg, Near- Ramakrishna Ashram Metro Station, Connaught Place, New Delhi",
    addressLinkJaipur:
      "https://www.google.com/maps/place/27,+Krishna+Nagar,+Gopal+Pura+Mode,+Jaipur,+Rajasthan+302015/@26.8718968,75.7812763,17z/data=!3m1!4b1!4m5!3m4!1s0x396db44aa90b1c59:0x90982a3c8b9b57e3!8m2!3d26.871892!4d75.783465",
    addressLinkDelhi:
      "https://www.google.com/maps/place/27,+Krishna+Nagar,+Gopal+Pura+Mode,+Jaipur,+Rajasthan+302015/@26.8718968,75.7812763,17z/data=!3m1!4b1!4m5!3m4!1s0x396db44aa90b1c59:0x90982a3c8b9b57e3!8m2!3d26.871892!4d75.783465",
  };
export { config, base, mainUrl, socialLinks, brandName, brandInfo };
