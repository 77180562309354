/*
*  @author       :  Prashant Kapoor
*  @license      :  MIT
*  @created      :  04/09/2021
*  @lastModified :  04/09/2021
*  @modifiedBy   :  Mukesh Majoka
*  @type         :  page
*/
import React, {useEffect} from 'react'
import '../../assets/scss/styles.scss'
import { base } from '../../constants/Data'
import Img from '../../elements/Img'
import * as RouterService from '../../services/Router'
import { translate as ln} from '../../services/Misc'
import { setTitle, setDescription } from '../../services/Meta'
const allowedStageUsers = [
    {
        name: 'Anjali Dharwal',
        userName: 'anjali',
        password: 'Anji196@com'
    },
    {
        name: 'Prashant Kapoor',
        userName: 'prashmaybe',
        password: 'Pk@9462678919'
    },
    {
        name: 'Sachin Choudhary',
        userName: 'sachin',
        password: 'sa679n12@'
    },
    {
        name: 'Bharat Mittal',
        userName: 'admin',
        password: 'Bo32@12'
    },
    {
        name: 'Lalitesh Upadhyay',
        userName: 'lalitesh',
        password: 'lays#eh345'
    },
    {
        name: 'Mukesh Majoka',
        userName: 'mukesh',
        password: 'muk#esh$'
    }
]
const UnderDevelopment = () => {
    useEffect(() => {
        setTitle('Under Development', 'public')
        setDescription('CreditQ is a web app-based platform for MSME that provides a facility for businessmen to report credit defaulters, settle payments & generate business Credit Reports.', 'public')
    }, [])
    const _authenticate = e => {
        e.preventDefault()
        let found = false
        let userName = document.getElementById('authKey').value
        let password = document.getElementById('authPass').value
        allowedStageUsers.forEach(el => {
            if(userName === el.userName && password === el.password) {
                found = true
            }
        })
        if (found) {
            sessionStorage.setItem('authUser', 'yes')
            RouterService.navigateTo('/')
        }
    }
    return <div className="body-background">
        <form className="errorpage auth-page" onSubmit={_authenticate}>
            <h2 style={{color: '#fff', fontSize: 32}}>{ln('UnderDevelopment')}</h2>
            <p>{ln('authkey')}</p>
            <div className="form-group">
                <input autoFocus={true} type="text" id="authKey" className="input" style={{height: '40px', padding: '10px 20px',margin: '10px'}} />
            </div>
            <div className="form-group">
                <input type="password" id="authPass" className="input" style={{height: '40px', padding: '10px 20px'}} />
            </div>
            <button className="btn-orange btn" type="submit">{ln('Enter')}</button>
        </form>
        <div className="logo-box">
            <a href="/" className="logo"><Img alt="CreditQ" src={base.imageBaseUrl+'logo.png'} /></a>
        </div>
    </div>
}
export default UnderDevelopment